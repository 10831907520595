
import { api } from "@/api/api";
import { ApiGetMemberOrganizationDto } from "@/api/generated/Api";
import BaseTooltipIconButton from "@/components/shared/button/BaseTooltipIconButton.vue";
import BaseSheet from "@/components/shared/sheet/BaseSheet.vue";
import BaseSheetField from "@/components/shared/sheet/BaseSheetField.vue";
import BaseSheetHeader from "@/components/shared/sheet/BaseSheetHeader.vue";
import { getInitialModalData, useOpenModal } from "@/fragments/modal/useOpenModal";
import { AdminRouteNames } from "@/shared/enums/RouteNames/adminRouteNamesEnum";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { useRoute, useRouter } from "@/shared/useHelpers";
import { defineComponent, onMounted, ref } from "@vue/composition-api";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import MemberOrganizationUserModal from "@/components/administration/member-organization-users/MemberOrganizationUserModal.vue";
import MemberOrganizationModal from "@/components/administration/member-organizations/MemberOrganizationModal.vue";
import BaseModal from "@/components/shared/BaseModal.vue";

export default defineComponent({
  name: "RegionOfficePage",
  components: {
    BaseSheet,
    BaseSheetHeader,
    BaseSheetField,
    BaseTooltipIconButton,
    BaseTableFiltered,
    MemberOrganizationUserModal,
    MemberOrganizationModal,
    BaseModal,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const regionOffice = ref<ApiGetMemberOrganizationDto>();
    const contentReady = ref(false);
    const modalData = ref(getInitialModalData());
    const offices = ref<ApiGetMemberOrganizationDto[]>([]);
    const mainOffice = ref();
    const regionOffices = ref<ApiGetMemberOrganizationDto[]>([]);
    const localOffices = ref<ApiGetMemberOrganizationDto[]>([]);
    const hasLocalOffices = ref(false);

    const openNewOrg = useOpenModal(ModalType.Add, "avdeling", modalData);
    const openEditOrg = useOpenModal(ModalType.Edit, "regionkontor", modalData);
    const viewMemberOrgUsers = useOpenModal(ModalType.Display, "Legg til e-post - regionkonto", modalData);

    const navigateToMemberOrg = () => {
      router.push({
        name: AdminRouteNames.MainOffice,
        params: { mainOfficeId: route.params.mainOfficeId },
      });
    };

    const viewRegionOffice = async (item: ApiGetMemberOrganizationDto) => {
      router.push({
        name: AdminRouteNames.RegionOffice,
        params: { mainOfficeId: route.params.mainOfficeId, regionOfficeId: item.id.toString() },
      });
    };

    const checkMemberOrgSettings = async () => {
      await globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        const response = (await api.organization.getMemberOrganizationFeatureSettings(+route.params.mainOfficeId)).data;

        if (response.hasMultiLevelMemberOrganization) {
          hasLocalOffices.value = true;
          // If org is multilevel, get lowest level offices
          getLocalOffices();
        }
      });
    };

    const viewLocalOffice = async (item: ApiGetMemberOrganizationDto) => {
      router.push({
        name: AdminRouteNames.LocalOffice,
        params: {
          mainOfficeId: route.params.mainOfficeId,
          regionOfficeId: route.params.regionOfficeId,
          localOfficeId: item.id.toString(),
        },
      });
    };

    const getAllRegionOffices = async () => {
      await globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        offices.value = (await api.organization.getMemberOrganizationsAsync()).data;
        if (mainOffice.value) {
          regionOffices.value = offices.value.filter((item) => item.parentId == mainOffice.value.id);
        }
      });
    };

    const getLocalOffices = async () => {
      await globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        localOffices.value = (
          await api.organization.getMemberOrganizationLocalOfficesAsync(+route.params.regionOfficeId, {
            includeInactive: true,
          })
        ).data.filter((x) => x.parentId == +route.params.regionOfficeId);
      });
    };

    onMounted(async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        regionOffice.value = (await api.organization.getMemberOrganizationAsync(+route.params.regionOfficeId)).data;
        mainOffice.value = (await api.organization.getMemberOrganizationAsync(+route.params.mainOfficeId)).data;
        await getAllRegionOffices();
        contentReady.value = true;
        await checkMemberOrgSettings();
      });
    });

    const headers = [
      { text: "Navn", value: "name" },
      { text: "Handlinger", value: "actions" },
      { text: "E-post", value: "email" },
      {
        text: "Aktiv",
        value: "isActive",
      },
    ];

    return {
      openNewOrg,
      headers,
      contentReady,
      regionOffice,
      viewLocalOffice,
      localOffices,
      navigateToMemberOrg,
      regionOffices,
      modalData,
      ModalType,
      getAllRegionOffices,
      viewRegionOffice,
      openEditOrg,
      viewMemberOrgUsers,
      hasLocalOffices,
      getLocalOffices,
    };
  },
});
