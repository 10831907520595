
import { api } from "@/api/api";
import { ApiGetMemberOrganizationDto } from "@/api/generated/Api";
import BaseTooltipIconButton from "@/components/shared/button/BaseTooltipIconButton.vue";
import BaseSheet from "@/components/shared/sheet/BaseSheet.vue";
import BaseSheetField from "@/components/shared/sheet/BaseSheetField.vue";
import BaseSheetHeader from "@/components/shared/sheet/BaseSheetHeader.vue";
import { getInitialModalData, useOpenModal } from "@/fragments/modal/useOpenModal";
import { AdminRouteNames } from "@/shared/enums/RouteNames/adminRouteNamesEnum";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { useRoute, useRouter } from "@/shared/useHelpers";
import { defineComponent, onMounted, ref } from "@vue/composition-api";
import MemberOrganizationUserModal from "@/components/administration/member-organization-users/MemberOrganizationUserModal.vue";
import MemberOrganizationModal from "@/components/administration/member-organizations/MemberOrganizationModal.vue";
import BaseModal from "@/components/shared/BaseModal.vue";

export default defineComponent({
  name: "LocalOfficePage",
  components: {
    BaseSheet,
    BaseSheetHeader,
    BaseSheetField,
    BaseTooltipIconButton,
    MemberOrganizationUserModal,
    MemberOrganizationModal,
    BaseModal,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const regionOffice = ref<ApiGetMemberOrganizationDto>();
    const contentReady = ref(false);
    const modalData = ref(getInitialModalData());
    const offices = ref<ApiGetMemberOrganizationDto[]>([]);
    const mainOffice = ref();
    const regionOffices = ref<ApiGetMemberOrganizationDto[]>([]);
    const localOffices = ref<ApiGetMemberOrganizationDto[]>([]);

    const openNewOrg = useOpenModal(ModalType.Add, "avdeling", modalData);
    const openEditOrg = useOpenModal(ModalType.Edit, "regionkontor", modalData);
    const viewMemberOrgUsers = useOpenModal(ModalType.Display, "Legg til e-post - regionkonto", modalData);

    const viewRegionOffice = async () => {
      router.push({
        name: AdminRouteNames.RegionOffice,
        params: { mainOfficeId: route.params.mainOfficeId, regionOfficeId: route.params.regionOfficeId },
      });
    };

    const getAllRegionOffices = async () => {
      await globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        offices.value = (await api.organization.getMemberOrganizationsAsync()).data;
        if (mainOffice.value) {
          regionOffices.value = offices.value.filter((item) => item.parentId == mainOffice.value.id);
        }
      });
    };

    onMounted(async () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        regionOffice.value = (await api.organization.getMemberOrganizationAsync(+route.params.regionOfficeId)).data;
        mainOffice.value = (await api.organization.getMemberOrganizationAsync(+route.params.mainOfficeId)).data;
        await getAllRegionOffices();
        contentReady.value = true;
      });
    });

    const headers = [
      { text: "Navn", value: "name" },
      { text: "Handlinger", value: "actions" },
      { text: "E-post", value: "email" },
      {
        text: "Aktiv",
        value: "isActive",
      },
    ];

    return {
      openNewOrg,
      headers,
      contentReady,
      regionOffice,
      localOffices,
      regionOffices,
      modalData,
      ModalType,
      getAllRegionOffices,
      viewRegionOffice,
      openEditOrg,
      viewMemberOrgUsers,
    };
  },
});
