var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', [_c('BaseSheet', {
    staticClass: "mt-3",
    attrs: {
      "outlined": false,
      "rounded": "lg"
    }
  }, [_vm.modalData.showModal ? _c('BaseModal', {
    on: {
      "close": function close($event) {
        _vm.modalData.showModal = false;
      }
    }
  }, [_vm.modalData.modalType === _vm.ModalType.Display ? _c('MemberOrganizationUserModal', {
    attrs: {
      "headline": _vm.modalData.modalHeadline,
      "modalType": _vm.modalData.modalType,
      "organizationId": _vm.modalData.existingItemId
    },
    on: {
      "close": function close($event) {
        _vm.modalData.showModal = false;
      }
    }
  }) : _c('MemberOrganizationModal', {
    attrs: {
      "headline": _vm.modalData.modalHeadline,
      "modalType": _vm.modalData.modalType,
      "mainOffice": _vm.modalData.existingItem,
      "officeId": _vm.modalData.existingItemId,
      "isRegionOfficeModal": true
    },
    on: {
      "close": function close($event) {
        _vm.modalData.showModal = false;
      },
      "update": _vm.getLocalOffices
    }
  })], 1) : _vm._e(), _c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_vm.contentReady ? _c('section', [_c('BaseSheetHeader', {
    staticClass: "px-2 py-4",
    attrs: {
      "data-cy": "personal-information-header",
      "title": "Regionkontor"
    },
    scopedSlots: _vm._u([{
      key: "prefix",
      fn: function fn() {
        return [_c('BaseTooltipIconButton', {
          staticClass: "ml-0",
          attrs: {
            "color": "primary",
            "btnText": "Tilbake til medlemsorganisasjon",
            "icon": "mdi-arrow-left"
          },
          on: {
            "handleClick": _vm.navigateToMemberOrg
          }
        })];
      },
      proxy: true
    }], null, false, 293258129)
  }), _c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "xs": "12",
      "sm": "6",
      "lg": "4"
    }
  }, [_c('BaseSheetField', {
    attrs: {
      "value": _vm.regionOffice.name,
      "label": "Navn"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "xs": "12",
      "sm": "6",
      "lg": "4"
    }
  }, [_c('BaseSheetField', {
    attrs: {
      "value": _vm.regionOffice.orgNo,
      "label": "Orgnr"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "xs": "12",
      "sm": "6",
      "lg": "4"
    }
  }, [_c('BaseSheetField', {
    attrs: {
      "value": _vm.regionOffice.phone,
      "label": "Telefonnummer"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "xs": "12",
      "sm": "6",
      "lg": "4"
    }
  }, [_c('BaseSheetField', {
    attrs: {
      "value": _vm.regionOffice.email,
      "label": "E-post"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "xs": "12"
    }
  }, [_c('BaseSheetField', {
    attrs: {
      "value": _vm.regionOffice.isActive,
      "label": "Aktiv"
    }
  })], 1)], 1), _c('v-divider', {
    staticClass: "divider"
  })], 1) : _vm._e()])], 1)], 1), _vm.hasLocalOffices ? _c('div', {
    staticClass: "mt-5"
  }, [_c('BaseTableFiltered', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.localOffices
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('BaseSheetHeader', {
          attrs: {
            "title": "Avdelinger"
          }
        }), _c('v-spacer'), _c('BaseTooltipIconButton', {
          attrs: {
            "color": "primary",
            "btnText": "Legg til avdeling",
            "icon": "mdi-plus"
          },
          on: {
            "handleClick": _vm.openNewOrg
          }
        })];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on,
                  attrs = _ref2.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                on: {
                  "click": function click($event) {
                    return _vm.viewLocalOffice(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-open-in-new ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Vis avdeling")])]), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on,
                  attrs = _ref3.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                on: {
                  "click": function click($event) {
                    return _vm.openEditOrg(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-pencil ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Rediger")])]), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref4) {
              var on = _ref4.on,
                  attrs = _ref4.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                staticClass: "mr-2",
                on: {
                  "click": function click($event) {
                    return _vm.viewMemberOrgUsers(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-account-cog ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Vis brukere")])])];
      }
    }, {
      key: "item.isActive",
      fn: function fn(_ref5) {
        var value = _ref5.value;
        return [_vm._v(" " + _vm._s(value ? "Ja" : "Nei") + " ")];
      }
    }], null, true)
  }, [_c('v-spacer')], 1)], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }