var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', [_c('BaseSheet', {
    staticClass: "mt-3",
    attrs: {
      "outlined": false,
      "rounded": "lg"
    }
  }, [_vm.modalData.showModal ? _c('BaseModal', {
    on: {
      "close": function close($event) {
        _vm.modalData.showModal = false;
      }
    }
  }, [_vm.modalData.modalType === _vm.ModalType.Display ? _c('MemberOrganizationUserModal', {
    attrs: {
      "headline": _vm.modalData.modalHeadline,
      "modalType": _vm.modalData.modalType,
      "organizationId": _vm.modalData.existingItemId
    },
    on: {
      "close": function close($event) {
        _vm.modalData.showModal = false;
      }
    }
  }) : _c('MemberOrganizationModal', {
    attrs: {
      "headline": _vm.modalData.modalHeadline,
      "modalType": _vm.modalData.modalType,
      "mainOffice": _vm.modalData.existingItem,
      "officeId": _vm.modalData.existingItemId,
      "isRegionOfficeModal": true
    },
    on: {
      "close": function close($event) {
        _vm.modalData.showModal = false;
      },
      "update": _vm.getAllRegionOffices
    }
  })], 1) : _vm._e(), _c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_vm.contentReady ? _c('section', [_c('BaseSheetHeader', {
    staticClass: "px-2 py-4",
    attrs: {
      "data-cy": "personal-information-header",
      "title": "Avdeling"
    },
    scopedSlots: _vm._u([{
      key: "prefix",
      fn: function fn() {
        return [_c('BaseTooltipIconButton', {
          staticClass: "ml-0",
          attrs: {
            "color": "primary",
            "btnText": "Tilbake til medlemsorganisasjon",
            "icon": "mdi-arrow-left"
          },
          on: {
            "handleClick": _vm.viewRegionOffice
          }
        })];
      },
      proxy: true
    }], null, false, 534021010)
  }), _c('v-row', {
    staticClass: "px-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "xs": "12",
      "sm": "6",
      "lg": "4"
    }
  }, [_c('BaseSheetField', {
    attrs: {
      "value": _vm.regionOffice.name,
      "label": "Navn"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "xs": "12",
      "sm": "6",
      "lg": "4"
    }
  }, [_c('BaseSheetField', {
    attrs: {
      "value": _vm.regionOffice.orgNo,
      "label": "Orgnr"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "xs": "12",
      "sm": "6",
      "lg": "4"
    }
  }, [_c('BaseSheetField', {
    attrs: {
      "value": _vm.regionOffice.phone,
      "label": "Telefonnummer"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "xs": "12",
      "sm": "6",
      "lg": "4"
    }
  }, [_c('BaseSheetField', {
    attrs: {
      "value": _vm.regionOffice.email,
      "label": "E-post"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "xs": "12"
    }
  }, [_c('BaseSheetField', {
    attrs: {
      "value": _vm.regionOffice.isActive,
      "label": "Aktiv"
    }
  })], 1)], 1), _c('v-divider', {
    staticClass: "divider"
  })], 1) : _vm._e()])], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }